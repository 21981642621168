// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var element = "RegisterForm-module--element--3VZhT";
export var button = "RegisterForm-module--button--Jl0FN";
export var content = "RegisterForm-module--content--UFRKP";
export var close = "RegisterForm-module--close--33W-H";
export var title = "RegisterForm-module--title--1Dpuq";
export var description = "RegisterForm-module--description--33ov9";
export var link = "RegisterForm-module--link--1U7p1";
export var form = "RegisterForm-module--form--2YTgS";
export var linkFooter = "RegisterForm-module--linkFooter--2jOWd";
export var formCheckGroup = "RegisterForm-module--formCheckGroup--10gVd";
export var checkmarkInput = "RegisterForm-module--checkmarkInput--3RNVg";
export var checkContainer = "RegisterForm-module--checkContainer--2_AiU";
export var container = "RegisterForm-module--container--2qqUh";
export var checkmark = "RegisterForm-module--checkmark--8gRK1";
export var formCheckLabel = "RegisterForm-module--formCheckLabel--369dp";
export var errorMessage = "RegisterForm-module--errorMessage--37CnK";
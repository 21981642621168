import React, { useRef } from "react";
import * as styles from "./Modal.module.scss";
import Close from "../../../assets/icons/x-modal.svg";
import ModalI from "./Modal.type";

const Modal = ({ type, open, setOpen }: ModalI) => {
  const ref = useRef<HTMLDivElement>(null);
  const closeModal = (e: any) => {
    if (ref.current === e.target) {
      setOpen(false);
    }
  };
  const title = type === "success" ? "Thank you!" : "Error";
  const description =
    type === "success"
      ? "We’ll be in contact soon."
      : "Oops, something went wrong. Please try again later.";
  return (
    <>
      {open ? (
        <div ref={ref} className={styles.element} onClick={closeModal}>
          <div className={styles.modal}>
            <img
              className={styles.close}
              src={Close}
              alt={"close"}
              onClick={() => setOpen(false)}
            />
            {type === "success" ? (
              <div className={styles.success} />
            ) : (
              <div className={styles.error} />
            )}
            <h3 className={styles.title}>{title}</h3>
            <p className={styles.description}>{description}</p>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Modal;

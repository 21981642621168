import React, { useRef, useState } from "react";
import * as styles from "./RegisterForm.module.scss";
import RegisterFormI from "./RegisterForm.type";
import Button from "../Button/Button";
import { ErrorMessage, Field, Form, Formik } from "formik";
import FormInput from "./FormInput/FormInput";
import * as Yup from "yup";
import Modal from "./Modal/Modal";
import { ModalType } from "./Modal/Modal.type";
import Close from "../../assets/icons/x-modal.svg";
import { cn } from "../../utils";

const RegisterForm = ({ modal, setModal }: RegisterFormI) => {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState(ModalType.SUCCESS);

  const closeModal = (e: any) => {
    if (ref.current === e.target) {
      setModal(false);
    }
  };
  return (
    <>
      {modal ? (
        <div ref={ref} className={styles.element} onClick={closeModal}>
          <div className={styles.content}>
            <img
              className={styles.close}
              src={Close}
              alt={"close"}
              onClick={() => setModal(false)}
            />
            <h1 className={styles.title}>Get Started with Via</h1>
            <p className={styles.description}>
              Already have an account?{" "}
              <a
                className={styles.link}
                href={"https://app.via.work/login"}
                onClick={e => {
                  e.preventDefault();
                }}
              >
                Sign In
              </a>
            </p>
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                company: "",
                numberOfEmployees: "",
                howCanWeHelpYou: "",
                acceptTerms: false,
              }}
              validationSchema={Yup.object().shape({
                firstName: Yup.string()
                  .min(2, "First name too short!")
                  .max(30, "First name too long!")
                  .required("First name is required"),
                lastName: Yup.string()
                  .min(2, "Last name too short!")
                  .max(30, "Last name too long!")
                  .required("Last name is required"),
                email: Yup.string()
                  .email("Invalid email")
                  .required("Email is required"),
                company: Yup.string(),
                numberOfEmployees: Yup.number().min(
                  1,
                  "Invalid number of employees"
                ),
                howCanWeHelpYou: Yup.string(),
                acceptTerms: Yup.bool().oneOf(
                  [true],
                  "Accepting Terms & Conditions is required"
                ),
              })}
              onSubmit={(values, { resetForm }) => {
                const url = `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.PORTAL_ID}/${process.env.FORM_ID}`;
                const data = {
                  fields: [
                    {
                      name: "firstname",
                      value: values.firstName,
                    },
                    {
                      name: "lastname",
                      value: values.lastName,
                    },
                    {
                      name: "email",
                      value: values.email,
                    },
                    {
                      name: "company",
                      value: values.company,
                    },
                    {
                      name: "numemployees",
                      value: values.numberOfEmployees,
                    },
                    {
                      name: "how_can_we_help_",
                      value: values.howCanWeHelpYou,
                    },
                  ],
                  context: {
                    pageUri: "via-work.com",
                    pageName: "Via Work",
                  },
                };
                fetch(url, {
                  method: "POST",
                  body: JSON.stringify(data),
                  headers: {
                    "Content-Type": "application/json",
                  },
                })
                  .then(() => {
                    setModalType(ModalType.SUCCESS);
                    setOpen(true);
                    resetForm();
                  })
                  .catch(error => {
                    setModalType(ModalType.ERROR);
                    setOpen(true);
                  });
              }}
            >
              {formik => {
                const { setFieldValue, errors, touched } = formik;
                return (
                  <Form className={styles.form}>
                    <Field
                      type="text"
                      name="firstName"
                      placeholder="First name"
                      component={FormInput}
                      required
                      medium
                    />
                    <Field
                      type="text"
                      name="lastName"
                      placeholder="Last name"
                      component={FormInput}
                      required
                      medium
                    />
                    <Field
                      type="email"
                      name="email"
                      placeholder="Business email"
                      component={FormInput}
                      required
                    />
                    <Field
                      type="text"
                      name="company"
                      placeholder="Company name"
                      component={FormInput}
                    />
                    <Field
                      type="number"
                      name="numberOfEmployees"
                      placeholder="Number of employees"
                      component={FormInput}
                      onChangeText={(value: any) =>
                        setFieldValue("numberOfEmployees", parseInt(value))
                      }
                    />
                    <Field
                      as="textarea"
                      tag="textarea"
                      type="textarea"
                      name="howCanWeHelpYou"
                      placeholder="How can we help you?"
                      component={FormInput}
                    />
                    <div className={styles.formCheckGroup}>
                      <div className={styles.checkContainer}>
                        <div className={styles.container}>
                          <Field
                            type="checkbox"
                            name="acceptTerms"
                            className={cn([
                              styles.checkmarkInput,
                              errors.acceptTerms && touched.acceptTerms
                                ? styles.isInvalid
                                : "",
                            ])}
                            required
                          ></Field>
                          <span className={styles.checkmark}></span>
                        </div>
                        <label
                          htmlFor="acceptTerms"
                          className={styles.formCheckLabel}
                        >
                          I agree to the{" "}
                          <i>
                            <a href="/terms-and-conditions/">
                              Terms & Conditions
                            </a>
                          </i>{" "}
                          and{" "}
                          <i>
                            <a href="/privacy-policy/">Privacy Policy</a>
                          </i>
                        </label>
                      </div>
                      <ErrorMessage
                        name="acceptTerms"
                        component="div"
                        className={styles.errorMessage}
                      />
                    </div>
                    <Button
                      className={styles.button}
                      text={"Submit"}
                      size={"large"}
                      color={"blue"}
                    />
                  </Form>
                );
              }}
            </Formik>
          </div>
          <Modal type={modalType} open={open} setOpen={setOpen} />
        </div>
      ) : null}
    </>
  );
};

export default RegisterForm;

export enum ModalType {
  SUCCESS = "success",
  ERROR = "error",
}

interface ModalI {
  type: "success" | "error";
  open: boolean;
  setOpen: (newValue: boolean) => void;
}

export default ModalI;

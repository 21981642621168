// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var element = "FormInput-module--element--1SX8I";
export var mb32 = "FormInput-module--mb32--q6As6";
export var input = "FormInput-module--input--3KcQL";
export var textarea = "FormInput-module--textarea--3eL5j";
export var medium = "FormInput-module--medium--13sDJ";
export var errors = "FormInput-module--errors--FKUMh";
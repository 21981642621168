// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var element = "Modal-module--element--2LWwo";
export var modal = "Modal-module--modal--2iMIM";
export var close = "Modal-module--close--SKEmh";
export var success = "Modal-module--success--2dcL6";
export var error = "Modal-module--error--10HRH";
export var title = "Modal-module--title--1SNFD";
export var description = "Modal-module--description--1k4Fy";
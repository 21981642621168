import React from "react";
import * as styles from "./FormInput.module.scss";
import FormInputI from "./FormInput.type";
import { cn } from "../../../utils";

const FormInput = ({
  field,
  form,
  required,
  placeholder,
  type = "text",
  tag,
  medium,
}: FormInputI) => {
  const Tag = tag ? "textarea" : "input";
  return (
    <div
      className={cn([
        medium ? styles.medium : "",
        styles.element,
        tag === "textarea" ? styles.mb32 : "",
      ])}
    >
      <Tag
        className={tag ? styles.textarea : styles.input}
        type={type}
        placeholder={placeholder}
        {...field}
        required={required}
      />
      {form.errors[field.name] && form.touched[field.name] && (
        <p className={styles.errors}>{form.errors[field.name]}</p>
      )}
    </div>
  );
};

export default FormInput;
